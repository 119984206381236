import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import {
	mapEdgesToNodes,
	filterOutDocsWithoutSlugs,
	filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import BlogPostPreviewList from '../components/blog-post-preview-list'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import PortableText from '../components/portableText'
import { FooterDefault } from '../components/footers/footer-default'
import { ContactSection } from '../components/sections/contact-section'
import { ReviewSection } from '../components/sections/review-section'
import { FaqSection } from '../components/sections/faq-section'

export const query = graphql`
  query FearPageQuery($id: String!, $trainingIds: [String]) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      	title
      	description
      	keywords
	},
	homepage: sanityHomePage {
		_rawServices
	}
    page: sanityFearPage(id: {eq: $id}) {
		reviews {
			reviewer
			training {
				training {
					name
					description
				}
				slug { 
					current
				}
				bannerImage {
					asset {
						fluid {
							...GatsbySanityImageFluid
						}
						url
					}
				}
			}
		}
		reviewVideoUrl
		_rawReviews
		_rawServices
		_rawRecognize
		_rawPerspective
		perspectiveImage {
			asset {
				url
				fluid {
					...GatsbySanityImageFluid
				}
			}
		}
		id
		slug {
			current
		}
		showBanner
		hideOverlay
		seoTitle
		seoKeywords
		seoDescription
		bannerTitle
		bannerSubTitle
		bannerImage {
			asset {
				url
				fluid {
					...GatsbySanityImageFluid
				}
			}
		}
		bannerCta {
			label
			url
		}
		fear {
			name,
			symptoms,
			_rawCause,
			_rawTherapy
		}
		cta {
			label
			url
		}
	},
	trainingPages: allSanityTrainingPage(filter: {
        training: {
          id:{
            in: $trainingIds
          }
        }
      }) {
        edges {
          node {
            slug {
                current
            }
            bannerImage {
                asset {
					url
					fluid {
						...GatsbySanityImageFluid
					}
                }
            }
            training {
                name
                description
                price
                unit
            }
          }
        }
      }
  }
`

const FearPage = ({ data = {}, errors }) => {

	if (errors) {
		return (
			<Layout page={data.page || {}}>
				<GraphQLErrorList errors={errors} />
			</Layout>
		)
	}

	const site = data.site || {}
	const { _rawRecognize, _rawPerspective, cta, fear = {}, reviews = [], _rawServices: _rawFearServices = [], _rawReviews = [], reviewVideoUrl = "", perspectiveImage = {} } = data.page || {}
	const { _rawServices = [] } = data.homepage || {}
	const { name, symptoms = [], _rawCause, _rawTherapy } = fear
	const { label: ctaLabel, url: ctaUrl } = cta || {}

	const trainingPages = (data.trainingPages || {}).edges || []

	const { asset = {} } = perspectiveImage || {}
	const { fluid } = asset || {}

	if (!site) {
		throw new Error(
			'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
		)
	}

	return (
		<Layout page={data.page || {}}>
			<Container>
				<section className="py-16 px-4 4xl:px-0 even:bg-gray-50 bg-white">
					<div className="max-w-6xl m-auto flex flex-col md:flex-row justify-around">
						{_rawRecognize && <div className="flex-1">
							<h3 className="font-roboto-slab text-3xl">
								Herken jij dit?
                    		</h3>
							<div className="mt-4 leading-loose">
								{_rawRecognize && <PortableText blocks={_rawRecognize} />}
							</div>
						</div>}
						<div className="flex-1 mt-8 md:mt-0 md:ml-16">
							<h3 className="font-roboto-slab text-3xl">
								Ervaar jij ook de volgende symptomen?
                    		</h3>
							<div className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
								{
									symptoms.map((symptom) => {
										return (
											<div className="font-roboto-slab py-2 px-4 text-lg text-orange-400 bg-orange-50 rounded-lg">{symptom}</div>
										)
									})
								}
							</div>
						</div>
					</div>
				</section>
				{cta && cta.url && <section className="bg-blue-500 px-4 4xl:px-0 py-16">
					<div className="flex flex-col md:flex-row max-w-6xl justify-between mx-auto">
						<h3 className="text-3xl text-white text-center md:text-left">Wij kunnen jou van je sociale angst afhelpen!</h3>
						<Link to={ctaUrl} className="block px-8 py-3 md:mt-0 mt-8 text-lg rounded-lg bg-orange-400 text-white font-semibold uppercase tracking-widest">{ctaLabel}</Link>
					</div>
				</section>}
				<ReviewSection hideAllTrainingsButton={true} _rawServices={((_rawFearServices && _rawFearServices.length > 0) ? _rawFearServices : _rawServices)} _rawReviews={_rawReviews} reviews={reviews} reviewVideoUrl={reviewVideoUrl} />
				{/* <section className="py-16 px-4 4xl:px-0 bg-gray-50">
					<div className="max-w-6xl m-auto flex flex-col md:flex-row justify-around">
						{_rawCause && <div className="flex-1">
							<h3 className="text-3xl font-roboto-slab">
								Die angst, waar komt die vandaan?
                    		</h3>
							<div className="mt-4 leading-loose">
								<PortableText blocks={_rawCause} />
							</div>
						</div>}
						<div className="flex-1 mt-8 md:mt-0 md:ml-16">
							<h3 className="text-3xl font-roboto-slab">
								Hoe Niels zijn angsten overwonnen heeft
                    		</h3>
							<iframe className="mt-4 w-full" src="https://player.vimeo.com/video/300128497" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen=""></iframe>
						</div>
					</div>
				</section> */}
				{trainingPages.length > 0 && <section className="py-16 px-4 md:px-0 4xl:px-0 even:bg-gray-50 bg-white">
					<div className="max-w-6xl mx-auto">
						<h3 className="font-roboto-slab text-3xl text-gray-800">
							Behandelingen voor {name}
						</h3>
						<div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 max-w-6xl mx-auto">

							{
								trainingPages.map(({ node: page }) => {
									const { id, bannerImage = {}, slug = {}, training = {} } = page
									const { asset } = bannerImage || {}
									const { url, fluid } = asset
									const { current } = slug
									const { name, description, price, unit } = training
									return (
										<Link to={current} className="flex flex-col rounded-lg overflow-hidden shadow-lg">
											<div className="flex-shrink-0 bg-white h-48">
												<Img className="h-48 w-full object-cover" fluid={fluid} alt="" />
											</div>
											<div className="flex-1 bg-white p-6 flex flex-col justify-between">
												<div className="flex-1">
													<p className="text-sm leading-5 font-medium text-orange-600">
														{price} {unit}
													</p>
													<div href="#" className="block">
														<h3 className="font-roboto-slab mt-2 text-xl leading-7 font-semibold text-gray-900">
															{name}
														</h3>
														<p className="mt-3 text-base leading-6 text-gray-500">
															{/* <PortableText blocks={_rawDescription} /> */}
															{description}
														</p>
													</div>
												</div>
											</div>
										</Link>
									)
								})
							}
						</div>
					</div>
				</section>}
				{(_rawCause || _rawPerspective) && <section className="py-16 px-4 4xl:px-0 even:bg-gray-50 bg-white">
					<div className="max-w-6xl m-auto flex flex-col md:flex-row justify-around">
						{_rawCause && _rawCause.length > 0 && <div className="flex-2">
							<h3 className="font-roboto-slab text-3xl">
								Waar komt die angst vandaan?
                    		</h3>
							<div className="mt-4 relative rounded-lg">
								<ClickToShowSmallScreens>
									<div className="leading-loose">
										<PortableText blocks={_rawCause} />
									</div>
								</ClickToShowSmallScreens>
							</div>
						</div>}
						{_rawPerspective && <div className="flex-1 mt-8 md:mt-0 md:ml-16">
							<h3 className="font-roboto-slab text-3xl">
								Wil je je ook gewoon weer op je gemak voelen?
                    		</h3>
							<div className="mt-4 leading-loose">
								<PortableText blocks={_rawPerspective} />
							</div>
							{fluid && <Img fluid={fluid} className="block mt-4 rounded-lg h-48 w-full" />}

						</div>}
					</div>
				</section>}
				<FaqSection />
				<ContactSection />

			</Container>
		</Layout>
	)
}

export default FearPage

const ClickToShowSmallScreens = ({ children, ...rest }) => {

	const [show, setShow] = useState(false)

	const classes = "sm:block " + (show ? "block" : "hidden")

	return (
		<div className="">
			<div className="font-roboto-slab sm:hidden text-orange-400 font-semibold py-4 hover:underline cursor-pointer" onClick={() => setShow(!show)}>+ Meer lezen</div>
			<div className={classes}>
				{children}
			</div>
		</div>
	)
}